import React from 'react';
import KeyPoint from '../../components/key-point';
import ArticleLayout from '../../layouts/article';

export default () => (
  <ArticleLayout category="service" title="物联网丨智能硬件" subtitle="智能硬件 / MCU / IoT 平台">
    <KeyPoint
      index="○"
      title="范围"
      content="运输和物流、工业制造、健康医疗、智能环境（家庭、办公、工厂）、个人和社会领域。"
    />
    <KeyPoint
      index="○"
      title="案例：租住科技"
      content="雄安盈家公寓管理系统 - 以小区内部署的网关为核心，通过线上签约与个人身份认证实现智能门锁的授权，自动缴费提醒，人员流动记录，智能化看房控制。"
    />
  </ArticleLayout>
);
